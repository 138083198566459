/* eslint no-undef: 0 */  // --> OFF

function initPopover() {
  const popover = document.querySelector('.popover');
  if (popover == undefined || popover == null) { return }

  const popoverLink = document.querySelector('.popover-link');

  $(document).on('scroll', function() {
    if (popover == undefined || popover == null) { return }

    popover.removeAttribute('data-show');
  })

  $(document).on('click', function(e) {
    if (popoverLink == undefined || popoverLink == null) { return }
    if (popover == undefined || popover == null) { return }

    if (e.target != popoverLink.children[0]) {
      popover.removeAttribute('data-show')
    }
  })
}

document.addEventListener('turbo:load', function() {
  initPopover()
});

document.addEventListener('turbo:render', function() {
  initPopover()
});
