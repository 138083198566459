import { Controller } from '@hotwired/stimulus'

// The sliders actions inside packs home
export default class extends Controller {
  static targets = [
    'prevSlide',
    'nextSlide',
    'contentPlatformCarouselWrapper',
    'currentSlide'
  ]

  connect() {
    this.initSlider();
    this.menuLinks = document.querySelectorAll(
      '.current-show__content-platform__functions__link'
    )
  }

  // Load pack slider
  initSlider() {
    window.contentPlatformCarousel = new window.Swiper(
      this.contentPlatformCarouselWrapperTarget,
      {}
    )

    let that = this;
    window.contentPlatformCarousel.on('slideChange', function () {
      that.toggleSlideClass();
    });
  }

  prevSlide(e) {
    e.preventDefault();

    window.contentPlatformCarousel.slidePrev()
    this.toggleSlideClass()
  }

  nextSlide(e) {
    e.preventDefault();

    window.contentPlatformCarousel.slideNext()
    this.toggleSlideClass()
  }


  toggleClass(element) {
    this.menuLinks.forEach(function (link) {
      if (link !== element) {
        return link.classList.remove('active')
      }

      link.classList.add('active')
    })
  }

  toggleSlideClass() {
    let carouseIndex = window.contentPlatformCarousel.activeIndex
    let slide = document.querySelector(
      `.current-show__content-platform__functions__link[data-index='${carouseIndex}']`
    )
    this.toggleClass(slide)
    this.currentSlideTarget.innerText = carouseIndex + 1;
  }

  goToSlide(e) {
    e.preventDefault()
    e.stopPropagation();

    this.toggleClass(e.currentTarget)
    window.contentPlatformCarousel.slideTo(e.currentTarget.dataset.index)
  }

  disconnect() {
  }
}
