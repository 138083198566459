import {Controller} from '@hotwired/stimulus';
import WaveSurfer from 'wavesurfer.js';

export default class extends Controller {
  connect() {
    this.url = atob(this.element.dataset.url);
    this.type = this.element.dataset.type;
    this.buttonContainer = this.element.querySelector('.btn-icon')
    this.thumbnailContainer = this.element.querySelector('.audio-thumbnail')
    this.buttonContainer.innerHTML = '<i class="fa fa-play"></i>';
    this.thumbnailContainer.innerHTML = '';

    let options = {};

    switch(this.type) {
      case 'stream_row_style':
        options = this.streamRowOptions(options);
        break;
      case 'stream_banner_style':
        options = this.streamBannerOptions(options);
        break;
      default:
        options = this.ecomOptions(options);
    }

    this.wavesurfer = WaveSurfer.create({
      container: this.thumbnailContainer,
      // this needs to remain web audio, media element puts the
      // url right onto the page
      backend: 'WebAudio',
      height: options['height'],
      waveColor: options['waveColor'],
      progressColor: options['progressColor'],
      cursorWidth: 0,
      hideScrollbar: false,
      autoCenter: true,
      fillParent: true,
      responsive: true
    });

    this.wavesurfer.on('finish', function () {
      this.stopPlayback();
    }.bind(this));

    this.wavesurfer.on('error', function (err) {
      console.error(err);
    }.bind(this));


    this.wavesurfer.load(this.url);

    window.addEventListener(
      'ma_audio_playback_start',
      this.maPlaybackStartReceived
    );

    window.addEventListener('resize', this.responsiveWave);
  }

  disconnect() {
    window.removeEventListener(
      'ma_audio_playback_start',
      this.maPlaybackStartReceived
    );

    window.removeEventListener('resize', this.responsiveWave);

    if (this.wavesurfer && this.wavesurfer.isPlaying()) {
      this.stopPlayback();
    }
  }

  ecomOptions(options) {
    options['height'] = 30;
    options['waveColor'] = '#b9bec8';
    options['progressColor'] = '#3d4554';
    return options;
  }

  streamRowOptions(options) {
    options['height'] = this.getHeight();
    options['waveColor'] = '#a3aec2';
    options['progressColor'] = '#8d7aff';
    return options;
  }

  streamBannerOptions(options) {
    options['height'] = 20;
    options['waveColor'] = '#a3aec2';
    options['progressColor'] = '#b9bec8';
    return options;
  }

  togglePlayback = () => {
    if (this.wavesurfer.isPlaying()) {
      this.stopPlayback();
    } else {
      this.dispatchAudioPlaybackEvent();
      this.startPlayback();
    }
  };

  dispatchAudioPlaybackEvent = () => {
    const event = new Event('ma_audio_playback_start');
    dispatchEvent(event);
  };

  startPlayback = () => {
    this.wavesurfer.seekTo(0);
    this.wavesurfer.play();

    if (this.wavesurfer.isPlaying()) {
      this.buttonContainer.innerHTML = '<i class="fa fa-pause"></i>';
    }
  };

  stopPlayback = () => {
    this.wavesurfer.pause();
    this.wavesurfer.seekTo(0);

    if (!this.wavesurfer.isPlaying()) {
      this.buttonContainer.innerHTML = '<i class="fa fa-play"></i>';
    }
  };

  maPlaybackStartReceived = (event) => {
    if (event.source !== this) {
      this.stopPlayback();
    }
  };

  responsiveWave = () => {
    const height = this.getHeight();
    this.wavesurfer.setHeight(height);
  };

  getHeight = () => {
    return parseInt(window.getComputedStyle(this.element).height);
  };
}
