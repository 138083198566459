import lozad from 'lozad'

document.addEventListener('turbo:load', function() {
  const observer = lozad('.lozad', {
    rootMargin: '1000px 0px',
    threshold: 0.1,
    enableAutoReload: true
  });
  observer.observe();
});

document.addEventListener('turbo:render', function() {
  const observer = lozad('.lozad', {
    rootMargin: '1000px 0px',
    threshold: 0.1,
    enableAutoReload: true
  });
  observer.observe();
});