import { Controller } from '@hotwired/stimulus';
import { FetchRequest } from '@rails/request.js';

// Handle registration modal on explore section
export default class extends Controller {
  static targets = [
    'emailField',
    'passwordField',
    'passwordContainer',
    'passwordConfirmationField',
    'passwordConfirmationContainer',
    'registrationModal',
    'showRegistrationModal'
  ]

  static values = {
    showInstantly: Boolean,
    registrationPath: String,
    alreadyRegistered: Boolean,
    newUser: Boolean
  }

  // Set default values on initialize
  initialize() {
    this.newUser = false;
    this.alreadyRegistered = false;
  }

  // Load necessary elements
  connect() {
    this.loadElements();
    this.buildModal();

    if (this.showInstantlyValue === true) {
      this.showRegistrationModal();
    }
  }

  // Load modal elements
  loadElements() {
    // Build modal HTML
    this.alertDiv = document.createElement('div');

    // Load modal selectors
    this.spinner = document.querySelector('.spinner-container');
    this.recoverLink = document.querySelector('.recover-link');
    this.statusMessage = document.querySelector('#status-text');
    this.headerMessage = document.querySelector('#header-text');
    this.welcomeMessage = document.querySelector('#welcome-text');
    this.registrationContent = document.querySelector('.registration-path');

    if (this.registrationContent !== undefined && this.registrationContent !== null) {
      this.registrationPathValue = this.registrationContent.dataset.path;
    }
  }

  // Build registration modal
  buildModal() {
    if (this.registrationPathValue == 'stream') {
      this.createModal();
    }
  }

  // Add error alert
  addErrorAlert(input) {
    input.classList.add('error');
  }

  // Email field validation
  emailValidation() {
    if (this.emailFieldTarget.value == '' &&
      this.emailFieldTarget.value != undefined) {
      this.addErrorAlert(
        this.emailFieldTarget,
        "The email can't be blank"
      );
    }
  }

  // Email format validation
  emailFormatValidation() {
    if (this.emailFormat.test(this.emailFieldTarget.value)) {
      this.addErrorAlert(
        this.emailFieldTarget,
        "The email is invalid"
      );
    }
  }

  // Submit request
  submit(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.alreadyRegistered) {
      this.registration()
    } else {
      this.validation()
    }

    window.setTimeout((function () {
      window.removeFlashMessage()
    }), 5000);
  }

  // Hadle validation to detect
  validation() {
    const that = this;

    this.request = new FetchRequest(
      'post',
      `/${this.registrationPathValue}/user_validation?email=${this.emailFieldTarget.value}`,
      {
        contentType: 'application/json',
        responseKind: 'json',
        body: JSON.stringify({})
      }
    )

    this.request.perform()
      .then(response => {
        response.text
          .then(result => {
            try {
              const parsedResult = JSON.parse(result);

              if (parsedResult['error']) {
                window.showFlashMessage('error', parsedResult['error']);
                return;
              }

              if (parsedResult.already_registered) {
                that.newUser = false;
                that.alreadyRegisteredMode()
              } else if (that.registrationPathValue == 'stream') {
                that.newUser = true
                that.newRegistrationMode();
              } else {
                that.newUser = true
                that.registration();
              }
            } catch (e) {
              console.log(e);
              window.showFlashMessage(
                'error',
                'Something went wrong, please try it again.'
              );
              return;
            }
          })
      })
  }

  // Email field validation
  alreadyRegisteredMode() {
    this.passwordContainerTarget.classList.remove('d-none');
    this.recoverLink.classList.remove('d-none');
    this.headerMessage.innerHTML = "Quick login"
    this.welcomeMessage.innerHTML = "Welcome back!"
    this.statusMessage.innerHTML = "Please login using your existing account details below."
    this.alreadyRegistered = true;
  }

  // Email field validation
  newRegistrationMode() {
    this.passwordContainerTarget.classList.remove('d-none');
    this.passwordConfirmationContainerTarget.classList.remove('d-none');
    this.headerMessage.innerHTML = "Quick Registration"
    this.welcomeMessage.innerHTML = "Welcome to Minimal Audio"
    this.statusMessage.innerHTML = "Please enter the password you’d like to use for your Minimal Audio Account."
    this.alreadyRegistered = true;
  }

  // Params validation for registered users
  registeredUserParams() {
    return {
      spree_user: {
        email: `${this.emailFieldTarget.value}`,
        password: `${this.passwordFieldTarget.value}`
      }
    }
  }

  // Params validation for new users
  registrationParams() {
    return {
      spree_user: {
        email: `${this.emailFieldTarget.value}`
      }
    }
  }

  // Handle registration params
  registrationFullParams() {
    return {
      spree_user: {
        email: `${this.emailFieldTarget.value}`,
        password: `${this.passwordFieldTarget.value}`,
        password_confirmation: `${this.passwordConfirmationFieldTarget.value}`
      }
    }
  }

  // Handle registration request
  registration() {
    let params = {};
    const that = this;

    if (this.newUser && this.registrationPathValue == 'stream') {
      params = this.registrationFullParams();
    } else if (this.newUser) {
      params = this.registrationParams();
    } else {
      params = this.registeredUserParams();
    }

    this.request = new FetchRequest(
      'post',
      `/${that.registrationPathValue}/user_registrations`,
      {
        contentType: 'application/json',
        responseKind: 'json',
        body: JSON.stringify(params)
      }
    )

    this.request.perform()
      .then(response => {
        response.text
          .then(result => {
            try {
              const parsedResult = JSON.parse(result);

              window.showFlashMessage(
                parsedResult.type,
                parsedResult.message
              )

              if (that.spinner) {
                that.spinner.style.display = 'none';
              }

              if (parsedResult['redirect_to']) {
                that.hideRegistrationModal();
                window.location.href = parsedResult['redirect_to'];
              }
            } catch (e) {
              // Avoid to print the error on the browser console
              // console.log(e);
              window.showFlashMessage(
                'error',
                'Something went wrong, please try it again.'
              );
              if (that.spinner) {
                that.spinner.style.display = 'none';
              }
            }
          })
      })
  }

  // Handle modal creation
  createModal() {
    const that = this;

    if (this.hasRegistrationModalTarget) {
      /* eslint-disable no-undef */
      this.registrationModal = new bootstrap.Modal(
        this.registrationModalTarget,
        { backdrop: true }
      );
      /* eslint-enable no-undef */
    }

    document.addEventListener('click', function (e) {
      let modal = document.getElementById('registration-modal')

      if (modal
        && !e.target.closest('.stream-registration-container')
        && modal.style.display === 'block') {
        that.clearModal();
      }
    })
  }

  // Display registration modal
  showRegistrationModal() {
    if (this.hasRegistrationModalTarget) {
      this.registrationModal.show();
    }
  }

  // Hide registration modal
  hideRegistrationModal() {
    if (this.hasRegistrationModalTarget) {
      this.registrationModal.hide();
    }
  }

  // Handle modal clear form values
  clearModal() {
    window.removeFlashMessage();
    this.emailFieldTarget.value = '';
    this.passwordContainerTarget.classList.add('d-none');
    this.passwordFieldTarget.value = '';
    this.passwordConfirmationContainerTarget.classList.add('d-none');
    this.passwordConfirmationFieldTarget.value = '';
    this.recoverLink.classList.add('d-none');
    this.headerMessage.innerHTML = "STREAM"
    this.welcomeMessage.innerHTML = "Sign up to access the Stream."
    this.statusMessage.innerHTML = "One month free. Why not start now?"
    this.alreadyRegistered = false;
    this.newUser = false;
  }

  disconnect() {
  }
}
