import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'
import { createPopper } from '@popperjs/core'
// import DeviceDetector from "device-detector-js";

const application = Application.start()

// Configure Stimulus development experience
application.warnings = true
application.debug = false
window.Stimulus = application
window.CreatePopper = createPopper
// window.DeviceDetector = DeviceDetector
window.bootstrap = require('bootstrap/dist/js/bootstrap')

const context = require.context('.', true, /\.js$/)
application.load(definitionsFromContext(context))

export { application }
