import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['dropdown', 'button', 'navPlugins', 'navExpansions']

  static values = {
    navPluginsBtnClass: {
      type: String,
      default: '.navbar-header-plugins',
    },
    navExpansionsBtnClass: {
      type: String,
      default: '.navbar-header-expansions',
    },
    navMobileBtnClass: {
      type: String,
      default: '.navbar-header-mobile',
    },
  }

  initialize() {
    this.activeBtn = null
    this.activeDropdown = null
    this.pluginsBtn = document.querySelector(this.navPluginsBtnClassValue)
    this.navbarMobileBtn = document.querySelector(this.navMobileBtnClassValue)
    this.expansionsBtn = document.querySelector(this.navExpansionsBtnClassValue)
    this.overlay = document.getElementById('app-header-overlay')
    this.resetDropdowns()
  }

  connect() {
    // On scroll header class
    this.handleScroll = this.handleScroll.bind(this)
    this.closeAllDropdowns = this.closeAllDropdowns.bind(this)
    window.addEventListener('scroll', this.handleScroll)
    this.handleScroll()

    // Close dropdowns on outside click or Esc
    this.overlay.addEventListener('click', (event) => this.closeDropdown(event))
    document.addEventListener('keydown', (event) => this.closeOnEsc(event))
  }

  disconnect() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll() {
    if (window.scrollY > 48) {
      this.element.classList.remove('header--static')
    } else {
      this.element.classList.add('header--static')
    }

    this.closeAllDropdowns()
  }

  closeAllDropdowns() {
    this.dropdownTargets.forEach((dropdown) => {
      if (!dropdown.classList.contains('tw-hidden')) {
        dropdown.classList.add('tw-hidden')
      }
    })
    if (this.overlay && !this.overlay.classList.contains('tw-hidden')) {
      this.overlay.classList.add('tw-hidden')
    }
    this.element.classList.remove('header--touched')
    if (this.activeBtn) {
      this.activeBtn.classList.remove('!tw-opacity-100', 'is-opened')
      this.activeBtn = null
    }
    this.activeDropdown = null
  }

  toggleDropdown(event) {
    const btn = event.currentTarget
    const dropdownId = btn.getAttribute('data-toggle-dropdown')
    const dropdown = this.dropdownTargets.find((d) => d.id === dropdownId)

    const isActivating = dropdown.classList.contains('tw-hidden')

    if (isActivating && this.activeDropdown) {
      this.activeDropdown.classList.add('tw-hidden')
      this.overlay.classList.add('tw-hidden')
      this.element.classList.remove('header--touched')
      if (this.activeBtn) {
        this.activeBtn.classList.remove('!tw-opacity-100')
      }
    }

    btn.classList.toggle('is-opened')
    dropdown.classList.toggle('tw-hidden')
    this.overlay.classList.toggle('tw-hidden')
    this.element.classList.add('header--touched')

    if (isActivating) {
      if (this.activeBtn) {
        this.activeBtn.classList.remove('!tw-opacity-100')
      }
      btn.classList.add('!tw-opacity-100')
      this.activeBtn = btn
    } else {
      btn.classList.remove('!tw-opacity-100')
      this.activeBtn = null
    }

    if (isActivating) {
      this.activeDropdown = dropdown
    } else {
      this.element.classList.remove('header--touched')
      this.activeDropdown = null
    }

    if (event && typeof event.stopPropagation === 'function') {
      event.stopPropagation()
    }
  }

  closeDropdown() {
    if (this.activeDropdown) {
      this.activeDropdown.classList.add('tw-hidden')
      this.overlay.classList.add('tw-hidden')
      this.element.classList.remove('header--touched')
      if (this.activeBtn) {
        this.activeBtn.classList.remove('!tw-opacity-100')
      }
      this.activeDropdown = null
      this.activeBtn = null
      this.navbarMobileBtn.classList.remove('is-opened')
    }
  }

  closeOnEsc(event) {
    if (event.key === 'Escape' && this.activeDropdown) {
      this.closeDropdown()
    }
  }

  resetDropdowns() {
    let that = this

    this.dropdownTargets.forEach(function (input) {
      if (input.classList.contains('tw-hidden')) {
        return
      }

      input.classList.add('tw-hidden')
      that.overlay.classList.add('tw-hidden')
      that.element.classList.remove('header--touched')
    })

    if (this.pluginsBtn != undefined || this.pluginsBtn != null) {
      if (this.pluginsBtn.classList.contains('!tw-opacity-100')) {
        this.pluginsBtn.classList.remove('!tw-opacity-100')
      }
    }

    if (this.expansionsBtn != undefined || this.expansionsBtn != null) {
      if (this.expansionsBtn.classList.contains('!tw-opacity-100')) {
        this.expansionsBtn.classList.remove('!tw-opacity-100')
      }
    }

    if (this.activeBtn != undefined || this.activeBtn != null) {
      this.activeBtn.classList.remove('!tw-opacity-100')
    }

    if (this.navbarMobileBtn != undefined || this.navbarMobileBtn != null) {
      this.navbarMobileBtn.classList.remove('is-opened')
    }

    this.activeDropdown = null
    this.activeBtn = null
  }
}
