import { Controller } from '@hotwired/stimulus';
import { FetchRequest } from '@rails/request.js';
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  static targets = [
    'displayCredits',
    'selectedContents',
    'creditCounter',
  ]

  static values = {
    numDownloaded: Number
  }

  connect() {
  }

  // this function takes an an array of content IDs
  // and their associated stimulus enabled rows on the frontend
  //
  // it then loops through each content ID and triggers a download
  // on the users side via JS, updates flash messages, and the
  // credit UI components
  createCreditTransactions(contentIds, uiElements, successMessage) {
    let url;
    let request;
    let contentID;

    const that = this;
    const contentType = uiElements[0].dataset.contentType;
    this.numDownloadedValue = 0;

    if (contentIds.length > 1) {
      url = '/stream/credits/bulk?';
    } else {
      url = '/stream/credits/create?';
    }

    url += 'content_id=' + contentIds;
    url += '&content_type=' + contentType;

    request = new FetchRequest('post', url, {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    });

    request.perform().then(response => {
      response.text.then(result => {
        const parsedResult = JSON.parse(result);

        // the transactions were created so download them
        switch (response.statusCode) {
          case 200:
            // download each element
            uiElements.forEach(function (element, idx) {
              element.classList.add('active');
              contentID = Array.isArray(parsedResult) ? parsedResult[idx].content_id : parsedResult.content_id;
              that.downloadFile(contentID, contentType, uiElements.length, successMessage, element, uiElements);
            });
            break;
          case 401:
            if (parsedResult.error === 'registration') {
              // Display registration modal if user is not logged in
              this.showRegistrationModal();
            } else {
              // Show subscribe modal if user doesn't have active subscription
              this.showSubscribeModal();
            }
            break;
          default:
            window.showFlashMessage('error', parsedResult.errors);
        }
      })
    })
  }

  // this downloads the actual file for the user
  downloadFile(contentID, contentType, numDownloads, successMessage, element, uiElements) {
    const that = this;
    let downloadRes = `/stream/credits/download`;

    downloadRes += '?content_id=' + contentID;
    downloadRes += '&content_type=' + contentType;

    fetch(downloadRes)
      .then(res => res.json())
      .then(res => {
        const url = Buffer.from(res.url, 'base64').toString('ascii')
        const name = res.name
        window.fileSaver.saveAs(url, `${name}.wav`);
        this.numDownloadedValue++;
      }).then(() => {


      if (this.numDownloadedValue === numDownloads) {
        window.showFlashMessage('success', successMessage);

        uiElements.forEach(function (local_element) {
          that.clearSelectedElement(local_element.dataset.contentId);
          that.updateTooltip(local_element.dataset.contentId);

          local_element.classList.add('active');
        });

        that.updateCounter();
      }
    })
  }

  updateCounter() {
    const that = this;
    let request = new FetchRequest('get', `/stream/credits/remaining`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        contentType: 'application/json',
        responseKind: 'json'
      }
    })

    request.perform().then(response => {
      response.text.then(result => {
        const parsedResult = JSON.parse(result);
        const creditRemaining = parsedResult.remaining;

        if (response.ok) {
          let creditText;

          if (parseInt(creditRemaining) === 1) {
            creditText = 'Credit'
          } else {
            creditText = 'Credits'
          }

          that.creditCounterTarget.innerHTML = `${creditRemaining} ${creditText}`;
        }
      })
    })
  }

  updateTooltip(elementId) {
    const downloadLink = document.querySelector(`.credit-transaction-link[data-value="${elementId}"]`);
    let downloadTooltipTarget = downloadLink.parentElement.children[0];
    let downloadTooltipTextTarget = downloadTooltipTarget.children[0];

    if (downloadLink.dataset.state === 'add' && downloadLink.classList.contains('active')) {
      downloadTooltipTarget.classList.add('enable');
      downloadTooltipTextTarget.innerHTML = 'Download Again'
    } else {
      downloadTooltipTarget.classList.remove('enable');
    }
  }

  clearSelectedElement(elementId) {
    const content = document.querySelector(`.check-content-link[data-value="${elementId}"]`);
    if (content && !content.classList.contains('active')) {
      return;
    }

    content.click();
  }

  showRegistrationModal() {
    let registrationModal = document.getElementById('registration-modal');

    const userValidationController = this.application.getControllerForElementAndIdentifier(
      registrationModal,
      'user-validation'
    );
    userValidationController.showRegistrationModal();
  }

  showSubscribeModal() {
    let modal = document.getElementById('subscribe-cta-modal');

    const controller = this.application.getControllerForElementAndIdentifier(
      modal,
      'stream--subscribe-cta'
    );
    controller.showSubscribeModal();
  }

  downloadContent(e) {
    e.preventDefault();
    const element = e.currentTarget;
    this.createCreditTransactions([element.dataset.contentId], [element], `Downloaded: ${element.dataset.contentName}`);
  }

  goToCurrent(e) {
    e.preventDefault();
    Turbo.visit('/products/current');
  }

  downloadContents(e) {
    e.preventDefault();

    let elements = [];
    const selectedContentElements = document.querySelectorAll('.check-content-link.active');
    const selectedContentIds = this.selectedContentsTarget.value.split(',');

    selectedContentElements.forEach(function (content) {
      let downloadElement = document.querySelector(`.credit-transaction-link[data-content-id='${content.dataset.value}']`);

      elements.push(downloadElement);
    })

    this.createCreditTransactions(selectedContentIds, elements, 'The Items Were Downloaded Successfully');
  }

  disconnect() {
  }
}
