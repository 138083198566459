/* eslint-disable */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'crypto-randomuuid'

import '../stylesheets/application'

require('@rails/activestorage').start()
require.context('../images', true)

window.Swiper = require('swiper').Swiper
window.fileSaver = require('./file_saver')
window.bootstrap = require('bootstrap/dist/js/bootstrap')

import './lity'
import './menu'
import './popover'
import './lozad'
import './file_saver'
import './subdomain'

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/css/brands';
import '@fortawesome/fontawesome-free/css/solid';
import '@fortawesome/fontawesome-free/css/regular';
import '@fortawesome/fontawesome-free/css/v4-shims';
import 'controllers'

import { Turbo } from '@hotwired/turbo-rails'

Turbo.session.drive = true
Turbo.setProgressBarDelay(1)
