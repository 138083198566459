import { Controller } from "@hotwired/stimulus"
import lottie from 'lottie-web';

import iconKnob from '../lotties/knob.json';
import iconWave from '../lotties/wave.json';
import iconMonitor from '../lotties/monitor.json';
import iconPlane from '../lotties/plane.json';

export default class extends Controller {

  connect() {

    this.direction = 1;

    const icons = {
      knob: iconKnob,
      wave: iconWave,
      monitor: iconMonitor,
      plane: iconPlane
    }

    let payload = JSON.parse(this.element.dataset.itemPayloadParam)

    this.element.innerHTML = "";
    this.animation = lottie.loadAnimation({
      container: this.element,
      render: 'svg',
      loop: false,
      autoplay: false,
      animationData: icons[payload['src']]
    })

    // If auto play is true, we do a forward to reverse loop
    if (payload["autoplay"]) {

      this.animation.setSpeed(1);
      this.animation.play();
      this.animation.addEventListener('complete', this.loop);

    } else {

      this.currentFrame = 0;
      this.animation.goToAndStop(this.currentFrame, true);
      window.addEventListener('scroll', this.handleScroll);

    }
  }

  disconnect() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  loop = () => {
    this.direction *= -1;
    this.animation.setDirection(this.direction);  
    this.animation.play();
  }

  handleScroll = () => {
    const icon = this.element;
    const centerY = icon.getBoundingClientRect().top + icon.getBoundingClientRect().height*0.5;
    const scrollPosition = this.clamp(centerY / window.innerHeight, 0, 1);

    let frame = 0;

    if (scrollPosition >= 0.5) {
      frame = this.mapRange(scrollPosition, 0.5, 1, this.animation.totalFrames, 0)
    } else {
      frame = this.mapRange(scrollPosition, 0, 0.5, 0, this.animation.totalFrames)
    }

    if (frame != this.currentFrame) {
      this.currentFrame = frame;
      this.animation.goToAndStop(this.currentFrame, true);
    }
  }

  mapRange = (source, sourceMin, sourceMax, destMin, destMax) => {
    return destMin + (destMax - destMin) * (source - sourceMin) / (sourceMax - sourceMin);
  }

  clamp = (val, min, max) => {
    return val > max ? max : val < min ? min : val;
  }

}