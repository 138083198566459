import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'paymentOption',
    'paypalCommerceContainer',
    'creditCardContainer'
  ]

  initialize() {
    this.paymentOptions = document.querySelectorAll('.payment-method-selector');
    this.paymentOptionsContent = document.querySelectorAll('.payment-methods-option');
  }

  connect() {
    this.setDefaultOption();
  }

  setDefaultOption() {
    const defaultOption = this.paymentOptions[0];

    defaultOption.checked = true;
    this.showSelectedPayment(defaultOption);
  }

  displayPayment(e) {
    const el = e.currentTarget;
    this.hideAll();
    this.showSelectedPayment(el);
  }

  addActiveAction() {
    this.paymentOptionsContent.forEach(function(input) {
      input.style.display = 'none';
    })
  }

  removeActiveAction() {
    this.paymentOptions.forEach(function(input) {
      input.parentElement.classList.remove('!tw-bg-neutral-300', 'tw-text-neutral-800');
    })
  }

  hideAll() {
    this.removeActiveAction();
    this.addActiveAction();
  }

  showSelectedPayment(el) {
    const paymentID = el.dataset.target;
    let paymentMethodOption = document.querySelector(paymentID);

    el.parentElement.classList.add('!tw-bg-neutral-300', 'tw-text-neutral-800');
    paymentMethodOption.style = 'display: block';
  }

  disconnect() {}
}
