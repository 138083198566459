import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['modal', 'card', 'backdrop']

  connect() {
    this.modal = this.modalTarget
  }

  showModal(event) {
    event.preventDefault()
    document.body.classList.add('tw-overflow-hidden')
    this.modal.classList.add('tw-flex')
    this.modal.classList.remove('tw-hidden')
    this.modal.setAttribute('aria-hidden', 'false')
    this.modal.focus()

    // Ensure the backdrop and card are visible before animating
    requestAnimationFrame(() => {
      this.backdropTarget.classList.replace('tw-opacity-0', 'tw-opacity-100')
      this.cardTarget.classList.remove('-tw-translate-y-12', 'tw-opacity-0')
      this.cardTarget.classList.add('tw-opacity-100')
    })
  }

  hideModal() {
    this.backdropTarget.classList.replace('tw-opacity-100', 'tw-opacity-0')
    this.cardTarget.classList.add('-tw-translate-y-12', 'tw-opacity-0')
    this.cardTarget.classList.remove('tw-opacity-100')

    // Wait for the animation to finish
    setTimeout(() => {
      this.modal.classList.remove('tw-flex')
      this.modal.classList.add('tw-hidden')
      this.modal.setAttribute('aria-hidden', 'true')
      document.body.classList.remove('tw-overflow-hidden')
    }, 300)
  }

  handleKeydown(event) {
    if (event.key === 'Escape') {
      this.hideModal()
    }
  }
}
