import { Controller } from '@hotwired/stimulus'

// The sliders actions inside packs home
export default class extends Controller {
  connect() {
    window.addEventListener('scroll', this.handleScroll)
    this.handleScroll()
  }

  // todo -- here we need to check what position we're scrolled to and highlight the
  // correct link
  handleScroll() {

  }

  disconnect() {}
}