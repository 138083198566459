import { Controller } from '@hotwired/stimulus'

// The sliders actions inside packs home
export default class extends Controller {
  static targets = [
    'prevSlide',
    'nextSlide',
    'planCarouselWrapper',
    'currentSlide'
  ]

  connect() {
    this.initSlider();
    this.menuLinks = document.querySelectorAll(
      '.current-show__plans__container__carousel__menu__content__link'
    )
  }

  // Load current plans slider
  initSlider() {
    window.planCarousel = new window.Swiper(
      this.planCarouselWrapperTarget,
      {}
    )

    let that = this;
    window.planCarousel.on('slideChange', function () {
      that.toggleSlideClass();
    });
  }

  prevSlide(e) {
    e.preventDefault();

    window.planCarousel.slidePrev()
    this.toggleSlideClass()
  }

  nextSlide(e) {
    e.preventDefault();

    window.planCarousel.slideNext()
    this.toggleSlideClass()
  }

  toggleClass(element) {
    this.menuLinks.forEach(function (link) {
      if (link !== element) {
        return link.classList.remove('active')
      }

      link.classList.add('active')
    })
  }

  toggleSlideClass() {
    let carouselIndex = window.planCarousel.activeIndex
    let slide = document.querySelector(
      `.current-show__plans__container__carousel__menu__content__link[data-index='${carouselIndex}']`
    )
    this.toggleClass(slide)
    this.currentSlideTarget.innerText = carouselIndex + 1;
  }

  goToSlide(e) {
    e.preventDefault()
    e.stopPropagation();

    this.toggleClass(e.currentTarget)
    window.planCarousel.slideTo(e.currentTarget.dataset.index)
  }

  disconnect() {}
}
