import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = [
    'subscriptionCouponCodeModal',
    'showSubscriptionCouponCodeModal',
    'couponCodeInput',
    'errorMessage'
  ]

  static values = {
    id: String
  }

  connect() {
    this.buildModal()
  }

  // Handle modal creation
  buildModal() {
    const that = this;

    if (this.hasSubscriptionCouponCodeModalTarget) {
      /* eslint-disable no-undef */
      this.subscriptionCouponCodeModal = new bootstrap.Modal(
        that.subscriptionCouponCodeModalTarget,
        { backdrop: true }
      );
      /* eslint-enable no-undef */
    }
  }

   // Submit request
  apply(e) {
    e.preventDefault()

    this.validation()
  }

  // Hadle validation to detect
  validation() {
    const that = this;

    this.request = new FetchRequest(
      'post',
      `/account/subscriptions/${this.idValue}/stripe_apply_coupon_code`,
      {
        contentType: 'application/json',
        responseKind: 'json',
        body: JSON.stringify({
          coupon_code: that.couponCodeInputTarget.value
        })
      }
    )

    this.request.perform()
      .then(response => {
        response.text
          .then(result => {
            try {
              const parsedResult = JSON.parse(result);

              if (parsedResult['type'] == 'error') {
                that.errorMessageTarget.innerHTML = parsedResult['message']
                return
              }

              that.errorMessageTarget.innerHTML = parsedResult['message']
            } catch (e) {
              console.log(e);
              return;
            }
          })
      })
  }


  // Display registration modal
  showModal(e) {
    e.preventDefault()

    if (this.hasSubscriptionCouponCodeModalTarget) {
      this.subscriptionCouponCodeModal.show()
    }
  }

  // Hide registration modal
  hideModal() {
    if (this.hasSubscriptionCouponCodeModalTarget) {
      this.subscriptionCouponCodeModal.hide()
    }
  }

  // Handle modal clear form values
  clearModal() {
    this.couponCodeInputTarget.value = ''
  }

  disconnect() {}
}
