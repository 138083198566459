/* eslint no-undef: 0 */  // --> OFF

function clickFunction() {
  const menuButton = document.getElementById('navbar-toggler');
  const menu = document.getElementById('menu');
  const navbar = document.getElementById('main-header');

  menu.classList.toggle('open');
  menuButton.firstElementChild.classList.toggle('active');
  navbar.classList.toggle('opacity-1');
}

function cleanUpMenu() {
  const menuButton = document.getElementById('navbar-toggler');
  if (menuButton == undefined || menuButton == null) { return }

  const menu = document.getElementById('menu');

  menu.classList.remove('open');
  menuButton.firstElementChild.classList.remove('active');
  menuButton.removeEventListener('click', clickFunction)
}

function initMenu() {
  const menuButton = document.getElementById('navbar-toggler');
  if (menuButton == undefined || menuButton == null) { return }

  menuButton.addEventListener('click', clickFunction)
}

document.addEventListener("turbo:before-cache", function() {
  cleanUpMenu();
});

document.addEventListener("turbo:load", function() {
  cleanUpMenu();
  initMenu();
});

document.addEventListener("turbo:render", function() {
  cleanUpMenu();
  initMenu();
});
