import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    customerId: String,
    authHash: String,
    appId: String,
    mode: String,
  }

  click(event) {
    event.preventDefault();
    event.stopPropagation();

    window.churnkey.init('show', {
      customerId: this.customerIdValue.trim(),
      authHash: this.authHashValue.trim(),
      appId: this.appIdValue.trim(),
      mode: this.modeValue.trim(),
      provider: 'stripe'
    })
  }
}
