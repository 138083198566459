import { Controller } from '@hotwired/stimulus';
import { createPopper } from '@popperjs/core';

export default class extends Controller {
  static targets = [
    'popoverMenu',
    'popoverContent'
  ]

  connect() {
    this.popover = createPopper(
      this.popoverMenuTarget,
      this.popoverContentTarget,
      {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ]
      }
    );
  }

  showMenu() {
    this.popoverContentTarget.setAttribute('data-show', '');
    this.popover.update();
  }

  hideMenu() {
    this.popoverContentTarget.removeAttribute('data-show');
    this.popover.update();
  }

  disconnect() {}
}
