import { Controller } from '@hotwired/stimulus';
import { Autoplay, FreeMode } from 'swiper/modules';

// The sliders actions inside packs home
export default class extends Controller {
  static targets = [
    'prevSlide',
    'nextSlide',
    'swiper'
  ]

  connect() {
    this.initSlider();
  }

  // Load pack slider
  initSlider() {
    this.slider = new window.Swiper(
      this.swiperTarget,
      {
        modules: [Autoplay, FreeMode],
        loop: true,
        wrapperClass: 'current-packs-swiper-wrapper',
        slideClass: 'current-packs-swiper-slide',
        speed: 6000,
        slidesPerView: 'auto',
        setWrapperSize: true,
        freeMode: {
          enabled: true,
          momentum: false,
          momentumBounce: false
        },
        touchMoveStopPropagation: true,
        touchStartForcePreventDefault: true
      }
    );
  }

  prevSlide(e) {
    e.preventDefault();
    e.stopPropagation();

    this.slider.update()
    this.slider.slidePrev(100);
  }

  nextSlide(e) {
    e.preventDefault();
    e.stopPropagation();

    this.slider.update()
    this.slider.slideNext(100);
  }

  disconnect() {
  }
}
