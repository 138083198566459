import { Controller } from '@hotwired/stimulus';

// The sliders actions inside packs home
export default class extends Controller {
  static targets = [
    'prevSlide',
    'nextSlide',
    'swiper'
  ]

  connect() {
    this.initSlider();
  }

  // Load pack slider
  initSlider() {
    this.slider = new window.Swiper(
      this.swiperTarget,
      {
        wrapperClass: 'new-packs-swiper-wrapper',
        slideClass: 'new-packs-swiper-slide',
        slidesPerView: 'auto'
      }
    );
  }

  prevSlide(e) {
    e.preventDefault();
    e.stopPropagation();

    this.slider.slidePrev();
  }

  nextSlide(e) {
    e.preventDefault();
    e.stopPropagation();

    this.slider.slideNext();
  }

  disconnect() {
  }
}
