import { Controller } from "@hotwired/stimulus"
import lottie from 'lottie-web';
import headerLogo from '../lotties/header-logo-light.json';

export default class extends Controller {
  connect() {
    let lottieContainer = document.getElementById('lottie-header');
    lottieContainer.innerHTML = "";
    this.lottieObj = lottie.loadAnimation({
      container: lottieContainer,
      render: 'svg',
      loop: false,
      autoplay: false,
      animationData: headerLogo
    })

    this.lottieObj.setSpeed(2);
    window.addEventListener('scroll', this.handleScroll);
    this.compacted = false;
    this.handleScroll();
  }

  disconnect() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    let navbar = document.getElementById('main-header');
    if (navbar) {
      if (window.scrollY <= 5) {
        if (navbar.classList.contains('on-scroll')) {
          navbar.classList.remove('on-scroll');
        }
        if (this.compacted == true) {
          this.lottieObj.setDirection(-1);
          this.lottieObj.play();
          this.compacted = false
        }
      } else if (window.scrollY > 60) {
        if (!navbar.classList.contains('on-scroll')) {
          navbar.classList.add('on-scroll');
        }
        if (this.compacted == false) {
          this.lottieObj.setDirection(1);
          this.lottieObj.play();
          this.compacted = true;
        }
      }
    }
  }
}
