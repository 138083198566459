import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'landing',
    'theEngine',
    'effectsSuite',
    'contentPlatform',
    'whatsNew'
  ]

  static values = {
    offset: Number,
    behavior: String
  }

  connect() {
    const that = this

    this.navbarTarget = document.querySelector('.navbar.sticky-top')
    this.menuLinks = document.querySelectorAll(
      '.current-show__feature-carousel-menu__link'
    )

    this.loadNavbarRange()
    this.loadlangingRange()
    this.loadTheEngineRange()
    this.loadEffectsSuiteRange()
    this.loadContentPlatformRange()
    this.loadWhatsNewRange()

    window.addEventListener('scroll', function() {
      that.checkViewportPosition()
    })
  }

  checkViewportPosition() {
    if (window.pageYOffset <= this.landingBotRange &&
        window.pageYOffset >= this.landingTopRange) {
      this.clearMenuPosition()
    }

    if (window.pageYOffset >= this.theEngineTopRange &&
        window.pageYOffset <= this.theEngineBotRange) {
      this.toggleClass(this.theEngineTarget)
    }

    if (window.pageYOffset >= this.effectsSuiteTopRange &&
        window.pageYOffset <= this.effectsSuiteBotRange) {
      this.toggleClass(this.effectsSuiteTarget)
    }

    if (window.pageYOffset >= this.contentPlatformTopRange &&
        window.pageYOffset <= this.contentPlatformBotRange) {
      this.toggleClass(this.contentPlatformTarget)
    }

    if (window.pageYOffset >= this.whatsNewTopRange) {
      this.clearMenuPosition()
    }
  }

  loadNavbarRange() {
    this.navbarBound = this.navbarTarget.getBoundingClientRect()
  }

  loadlangingRange() {
    this.landingBound = this.landingTarget.getBoundingClientRect()
    this.landingTopRange = 0
    this.landingBotRange = this.landingBound.height + this.navbarBound.height
  }

  loadTheEngineRange() {
    this.theEngineBound = this.theEngineTarget.getBoundingClientRect()
    this.theEngineTopRange = this.landingBotRange
    this.theEngineBotRange =
      this.theEngineTopRange + this.theEngineBound.height - 1
  }

  loadEffectsSuiteRange() {
    this.effectsSuiteBound = this.effectsSuiteTarget.getBoundingClientRect()
    this.effectsSuiteTopRange = this.theEngineBotRange
    this.effectsSuiteBotRange =
      this.effectsSuiteTopRange + this.effectsSuiteBound.height - 1
  }

  loadContentPlatformRange() {
    this.contentPlatformBound =
      this.contentPlatformTarget.getBoundingClientRect()
    this.contentPlatformTopRange = this.effectsSuiteBotRange
    this.contentPlatformBotRange =
      this.contentPlatformTopRange + this.contentPlatformBound.height - 1
  }

  loadWhatsNewRange() {
    this.whatsNewBound =
      this.whatsNewTarget.getBoundingClientRect()
    this.whatsNewTopRange = this.contentPlatformBotRange
    this.whatsNewBotRange =
      this.whatsNewTopRange + this.whatsNewBound.height - 1
  }

  clearMenuPosition() {
    this.menuLinks.forEach(function(link) {
      link.classList.remove('active')
    })
  }

  scroll(e) {
    e.preventDefault()

    const id = e.currentTarget.hash.replace(/^#/, '')
    const target = document.getElementById(id)

    if (!target) {
      return
    }

    const targetBoundingClientRectTop = target.getBoundingClientRect().top
    const elementPosition = targetBoundingClientRectTop + window.pageYOffset
    const offsetPosition = elementPosition - 76

    // this.toggleClass(e.currentTarget)

    window.scrollTo({
      top: offsetPosition,
      behavior: this.behavior()
    })
  }

  toggleClass(el) {
    this.menuLinks.forEach(function(link) {
      if (link.dataset.section !== el.dataset.section) {
        return link.classList.remove('active')
      }

      link.classList.add('active')
    })
  }

  processIntersectionEntries(entries) {
    const that = this

    entries.forEach(entry => {
      if (entry.isIntersecting) {
        that.toggleClass(entry)
      }
    })
  }

  behavior() {
    return this.behaviorValue || 'smooth'
  }

  disconnect() {}
}
